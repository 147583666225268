.project-list-item .first-section {
    position: relative;
}

.project-list-item .first-section>img {
    width: 100%;
    height: 200px;
}

.project-list-item .second-section {
    background-color: 'white';
    border-top: 1px solid #e3e3e3;
    padding: 0px 15px
}

.project-list-item .second-section>div h4 {
    color: #212121;
    font-size: 20px
}

.project-list-item .second-section>div p {
    color: #9c9c9c;
    text-decoration: none
}

.project-list-item .second-section i {
    color: #9c9c9c
}

.project-list-item:hover{
    text-decoration: none
}

.project-list-item>div>p{
    color: #3b3a3a;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none solid rgb(59, 58, 58);
    letter-spacing: 1px;
    transition: color 0.3s ease-in-out;
}

.project-list-item>div>p:hover{
    color: #1C66D1;
}

.project-list-item .top-left {
    position: absolute;
    top: 8px;
    left: 0px;
    color: #f1f1f1;
    padding: 5px;
    margin-left: 15px;
    opacity: 0.8;
}

.project-list-item .top-left>img {
    height: 31px;
    display: inline-block;
    border: 2px solid #f87739;
}

.project-list-item .top-left>p {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0px;
    background-color: #f87739;
    position: absolute;
    height: 31px;
    left: 20px;
    top: 5px;
    width: 146px;
    white-space: nowrap;
    padding-left: 27px;
    padding-top: 5px;
    padding-bottom: 1px;
    z-index: -1;
    opacity: 0.8;
    border-radius: 6px;
    font-style: italic;
}

.project-list-item .project-list-item-date {
    font-size: 15px;
    color: #9c9c9c;
    font-weight: 300;
    text-decoration: none solid rgb(156, 156, 156);
    letter-spacing: 1px;
    font-style: italic;
}
