html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Montserrat, sans-serif;
}

body {
    background-color: #f7f7f7;
}

button:focus {
    outline: 0 !important;
}

button {
    outline: 0 !important;
}

button:active {
    outline: 0 !important;
}

.form-control:focus {
    border-color: #ccc !important;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #ccc !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #ccc !important;
}
