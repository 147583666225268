.model-rendering .horizontal-nav-container {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    margin: 0 25%;
    height: 25px;
}

.model-rendering  .horizontal-nav-container::-webkit-scrollbar {
    display: none;
}

.model-rendering .horizontal-nav-Contents {
    float: left;
    padding-right: 150px;
    margin-bottom: 5px;
}

.model-rendering .horizontal-nav-Contents h4, .model-rendering .horizontal-nav-Contents h4:hover {
    display: inline;
    margin-right: 20px;
}

.model-rendering .horizontal-nav-Contents h4:hover {
    cursor: pointer;
    border-bottom: 3px solid #ff7f2a;
}

.model-rendering #rendering-canvas {
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    border: 1px solid #c2c2c2;
}
