.project-list-wrapper>.container {
    padding-top: 50px;
    width: auto;
    background-color: white;
    margin-bottom: 50px;
    padding-bottom: 50px;
    margin-right: 5%;
    margin-left: 5%;
}

.project-list-wrapper .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
}

.project-list-wrapper .infinite-scroll-component>.project-list-item-container {
    background-color: white;
    width: 430px;
    padding: 0 20px;
    margin: 20px 0;
}

.project-list-wrapper .empty-projects {
    font-size: 24px;
    color: #595959;
    text-decoration: none solid rgb(89, 89, 89);
    margin-top: 124px;
}

.project-list-wrapper .infinite-scroll-component>.project-list-item-container .project-list-item-card {
    border: 1px solid #e3e3e3;
}

@media all and (max-width: 2200px) and (min-width: 2000px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 400px;
    }
}

@media all and (max-width: 2000px) and (min-width: 1700px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 350px;
    }
}

@media all and (max-width: 1700px) and (min-width: 1500px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 400px;
    }
}

@media all and (max-width: 1500px) and (min-width: 1400px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 350px;
    }
}

@media all and (max-width: 1400px) and (min-width: 1200px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 450px;
    }
}

@media all and (max-width: 1200px) and (min-width: 1000px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 350px;
    }
}

@media all and (max-width: 1000px) {
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 450px;
    }
}
