.Card {
    margin-bottom: 0;
    position: relative;
    padding: 45px 15px;
    margin: 0 -15px 15px;
}

.Card .CardTitle {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 12px;
    font-weight: 700;
    color: #959595;
    text-transform: uppercase;
    letter-spacing: 1px;
    content: "Example";
}

@media (min-width: 768px) {
    .Card {
        margin-right: 0;
        margin-left: 0;
        border-width: 1px;
    }
}

@media (min-width: 768px) {
    .Card {
        background-color: #fff;
        border-color: #ddd;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
