.main-wrapper {
    background-size: 100%;
    background-size: cover;
    background-attachment: fixed;
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-wrapper .container {
    width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.main-wrapper .row {
    margin-right: 0px;
    margin-left: 0px;
}

.main-wrapper main {
    flex: 2 auto;
    padding-top: 60px;
}

.thumbnail img {
    width: 100%;
}

.model-rendering-wrapper {
    margin-bottom: 40px;
    padding: 0px
}

#canvasParent {
    height: 500px;
    width: 100%;
    border: 1px solid #c2c2c2;
}

#demoButton {
    position: absolute;
    top: 0
}

#theNav>li.active>a {
    color: #1C66D1;
    border-bottom: 3px solid #1C66D1;
}

#theNav>li>a {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #666666;
    padding: 0;
    width: 88px;
}

.panel-heading, .panel-body {
    padding: 5px;
}

.panel-body {
    color: #c2c2c2;
}

.active-color {
    color: #1C66D1;
}

.project-edit-link {
    margin-left: 10px;
    font-size: 24px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    margin-bottom: 16px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
    padding: 2px 16px;
    background-color: #f2f2f2;
}

.card-img-container {
    position: relative;
}

.card-img-container .card-img-content {
    position: absolute;
    top: 0;
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.card-img-container .card-img-transparent {
    background: rgba(248, 119, 57, 0.5);
}

.card-img-content div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
}

.top-right {
    position: absolute;
    top: 8px;
    right: 0px;
    color: #f1f1f1;
    background: #f87739;
    padding: 5px;
}

.card-link, .card-link:hover, .card-link:active {
    color: #333;
    text-decoration: none;
}

/* Large Devices, Wide Screens */

.category-panel {
    background: #fff;
    margin-bottom: 10px;
}

.category-panel-header {
    padding: 1em;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.category-panel-header div {
    width: 50%;
}

.category-panel-header div:first-child {
    text-align: left;
}

.category-panel-header div:last-child {
    text-align: right;
}

.category-panel-header div:last-child i {
    vertical-align: middle;
}

.category-panel-header h4 {
    margin: 0;
}

.left-button, .right-button {
    background: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    top: 0;
    margin: 0 -2em;
}

.left-button:focus, .right-button:focus {
    outline: 0;
}

.left-button {
    left: 25%;
}

.right-button {
    right: 25%;
}

.active-nav {
    color: #ff7f2a;
    border-bottom: 3px solid #ff7f2a;
}

.category-list-title {
    line-height: 25px;
}

.table-wrapper-2 {
    display: block;
    max-height: 250px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* Project Home Container */

.project-home-container {
    width: 1535px;
    margin-top: 2%;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: lightslategrey;
    border-radius: 10px;
}

/*custom checkbox */

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled+label {
    color: white !important;
    cursor: not-allowed;
}

.custom-checkbox>.checkbox>label>[type="checkbox"], .custom-checkbox>label {
    margin-bottom: 0px !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 300;
    color: white;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked), .custom-checkbox>.checkbox>label>[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label, .custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 0px;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label:after, .custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label:after {
    font: normal normal normal 12px/1 'Glyphicons Halflings';
    content: '\e013';
    position: absolute;
    top: 50%;
    margin-top: -6px;
    left: 4px;
    color: white;
    transition: all .2s;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
}

.custom-checkbox>.checkbox>label>[type="checkbox"][data-indeterminate]+label:after, .custom-checkbox>.checkbox>label>[type="checkbox"][data-indeterminate]+label:after {
    content: '\2212';
    left: 2px;
    opacity: 1;
    transform: scale(1);
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:not(:checked)+label:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:checked+label:before {
    box-shadow: none;
    background-color: transparent;
    border-color: #eeeeee;
    cursor: not-allowed;
    opacity: 1;
    color: #dadada;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:checked+label:after {
    color: #dadada;
    cursor: not-allowed;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled+label {
    color: #aaa;
    cursor: not-allowed;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:checked:focus+label:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked):focus+label:before {
    border: 1px solid #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.custom-checkbox>label:hover:before {
    border: 1px solid white !important;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:not(:checked)+label:hover:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:checked+label:hover:before {
    border: 1px solid white !important;
}

.custom-checkbox>.checkbox label, .radio label {
    padding-left: 0px;
}

.form-control, .btn, .Select-control {
    border-radius: 0;
}

.form-required label:after {
    content: " * ";
    color: red;
}
