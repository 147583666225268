.main-wrapper {
    background-size: 100%;
    background-size: cover;
    background-attachment: fixed;
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-wrapper .container {
    width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.main-wrapper .row {
    margin-right: 0px;
    margin-left: 0px;
}

.main-wrapper main {
    flex: 2 1 auto;
    padding-top: 60px;
}

.thumbnail img {
    width: 100%;
}

.model-rendering-wrapper {
    margin-bottom: 40px;
    padding: 0px
}

#canvasParent {
    height: 500px;
    width: 100%;
    border: 1px solid #c2c2c2;
}

#demoButton {
    position: absolute;
    top: 0
}

#theNav>li.active>a {
    color: #1C66D1;
    border-bottom: 3px solid #1C66D1;
}

#theNav>li>a {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #666666;
    padding: 0;
    width: 88px;
}

.panel-heading, .panel-body {
    padding: 5px;
}

.panel-body {
    color: #c2c2c2;
}

.active-color {
    color: #1C66D1;
}

.project-edit-link {
    margin-left: 10px;
    font-size: 24px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    width: 100%;
    margin-bottom: 16px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
    padding: 2px 16px;
    background-color: #f2f2f2;
}

.card-img-container {
    position: relative;
}

.card-img-container .card-img-content {
    position: absolute;
    top: 0;
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.card-img-container .card-img-transparent {
    background: rgba(248, 119, 57, 0.5);
}

.card-img-content div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 1.5em;
}

.top-right {
    position: absolute;
    top: 8px;
    right: 0px;
    color: #f1f1f1;
    background: #f87739;
    padding: 5px;
}

.card-link, .card-link:hover, .card-link:active {
    color: #333;
    text-decoration: none;
}

/* Large Devices, Wide Screens */

.category-panel {
    background: #fff;
    margin-bottom: 10px;
}

.category-panel-header {
    padding: 1em;
    display: flex;
}

.category-panel-header div {
    width: 50%;
}

.category-panel-header div:first-child {
    text-align: left;
}

.category-panel-header div:last-child {
    text-align: right;
}

.category-panel-header div:last-child i {
    vertical-align: middle;
}

.category-panel-header h4 {
    margin: 0;
}

.left-button, .right-button {
    background: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    top: 0;
    margin: 0 -2em;
}

.left-button:focus, .right-button:focus {
    outline: 0;
}

.left-button {
    left: 25%;
}

.right-button {
    right: 25%;
}

.active-nav {
    color: #ff7f2a;
    border-bottom: 3px solid #ff7f2a;
}

.category-list-title {
    line-height: 25px;
}

.table-wrapper-2 {
    display: block;
    max-height: 250px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* Project Home Container */

.project-home-container {
    width: 1535px;
    margin-top: 2%;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: lightslategrey;
    border-radius: 10px;
}

/*custom checkbox */

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled+label {
    color: white !important;
    cursor: not-allowed;
}

.custom-checkbox>.checkbox>label>[type="checkbox"], .custom-checkbox>label {
    margin-bottom: 0px !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 300;
    color: white;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked), .custom-checkbox>.checkbox>label>[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label, .custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 0px;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label:after, .custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label:after {
    font: normal normal normal 12px/1 'Glyphicons Halflings';
    content: '\E013';
    position: absolute;
    top: 50%;
    margin-top: -6px;
    left: 4px;
    color: white;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.custom-checkbox>.checkbox>label>[type="checkbox"][data-indeterminate]+label:after, .custom-checkbox>.checkbox>label>[type="checkbox"][data-indeterminate]+label:after {
    content: '\2212';
    left: 2px;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:not(:checked)+label:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:checked+label:before {
    box-shadow: none;
    background-color: transparent;
    border-color: #eeeeee;
    cursor: not-allowed;
    opacity: 1;
    color: #dadada;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:checked+label:after {
    color: #dadada;
    cursor: not-allowed;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled+label {
    color: #aaa;
    cursor: not-allowed;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:checked:focus+label:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:not(:checked):focus+label:before {
    border: 1px solid #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.custom-checkbox>label:hover:before {
    border: 1px solid white !important;
}

.custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:not(:checked)+label:hover:before, .custom-checkbox>.checkbox>label>[type="checkbox"]:disabled:checked+label:hover:before {
    border: 1px solid white !important;
}

.custom-checkbox>.checkbox label, .radio label {
    padding-left: 0px;
}

.form-control, .btn, .Select-control {
    border-radius: 0;
}

.form-required label:after {
    content: " * ";
    color: red;
}

.Card {
    margin-bottom: 0;
    position: relative;
    padding: 45px 15px;
    margin: 0 -15px 15px;
}

.Card .CardTitle {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 12px;
    font-weight: 700;
    color: #959595;
    text-transform: uppercase;
    letter-spacing: 1px;
    content: "Example";
}

@media (min-width: 768px) {
    .Card {
        margin-right: 0;
        margin-left: 0;
        border-width: 1px;
    }
}

@media (min-width: 768px) {
    .Card {
        background-color: #fff;
        border-color: #ddd;
        box-shadow: none;
    }
}

.footer-wrapper {
    width: 100%;
    display: inline-block;
    background-color: white;
    border: 1px solid #e0e0e0;
}

.footer-wrapper .logo-wrapper {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
}

.footer-wrapper .circle {
    border-radius: 85px;
    background: #1C66D1;
    color: white;
}

.footer-wrapper img {
    height: 70px;
}

.footer-wrapper .logo-text {
    text-align: left;
    padding-left: 15px;
    flex: 1 1 auto;
}

.footer-wrapper .footer-text {
    font-size: 16px;
    color: #9c9c9c;
    font-weight: 300;
    letter-spacing: 1px;
}

.footer-wrapper .terms-text {
    text-align: right;
    padding-left: 15px;
}

.footer-wrapper .navbar-brand {
    padding: 0;
}

@media all and (max-width: 1500px) {
    .footer-wrapper .container {
        width: 100%;
    }
}

.SpinLoader .spinner-wrapper {
    top: '50%';
    left: '50%';
}

.SpinLoader .spinner-message {
    color: #595959;
    letter-spacing: 1;
}

.model-rendering .horizontal-nav-container {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    margin: 0 25%;
    height: 25px;
}

.model-rendering  .horizontal-nav-container::-webkit-scrollbar {
    display: none;
}

.model-rendering .horizontal-nav-Contents {
    float: left;
    padding-right: 150px;
    margin-bottom: 5px;
}

.model-rendering .horizontal-nav-Contents h4, .model-rendering .horizontal-nav-Contents h4:hover {
    display: inline;
    margin-right: 20px;
}

.model-rendering .horizontal-nav-Contents h4:hover {
    cursor: pointer;
    border-bottom: 3px solid #ff7f2a;
}

.model-rendering #rendering-canvas {
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    border: 1px solid #c2c2c2;
}

.project-list-item .first-section {
    position: relative;
}

.project-list-item .first-section>img {
    width: 100%;
    height: 200px;
}

.project-list-item .second-section {
    background-color: 'white';
    border-top: 1px solid #e3e3e3;
    padding: 0px 15px
}

.project-list-item .second-section>div h4 {
    color: #212121;
    font-size: 20px
}

.project-list-item .second-section>div p {
    color: #9c9c9c;
    text-decoration: none
}

.project-list-item .second-section i {
    color: #9c9c9c
}

.project-list-item:hover{
    text-decoration: none
}

.project-list-item>div>p{
    color: #3b3a3a;
    font-size: 16px;
    font-weight: 300;
    -webkit-text-decoration: none solid rgb(59, 58, 58);
            text-decoration: none solid rgb(59, 58, 58);
    letter-spacing: 1px;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
}

.project-list-item>div>p:hover{
    color: #1C66D1;
}

.project-list-item .top-left {
    position: absolute;
    top: 8px;
    left: 0px;
    color: #f1f1f1;
    padding: 5px;
    margin-left: 15px;
    opacity: 0.8;
}

.project-list-item .top-left>img {
    height: 31px;
    display: inline-block;
    border: 2px solid #f87739;
}

.project-list-item .top-left>p {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0px;
    background-color: #f87739;
    position: absolute;
    height: 31px;
    left: 20px;
    top: 5px;
    width: 146px;
    white-space: nowrap;
    padding-left: 27px;
    padding-top: 5px;
    padding-bottom: 1px;
    z-index: -1;
    opacity: 0.8;
    border-radius: 6px;
    font-style: italic;
}

.project-list-item .project-list-item-date {
    font-size: 15px;
    color: #9c9c9c;
    font-weight: 300;
    -webkit-text-decoration: none solid rgb(156, 156, 156);
            text-decoration: none solid rgb(156, 156, 156);
    letter-spacing: 1px;
    font-style: italic;
}

.ProjectListToolbar .transparent {
    background-color: #fff;
    box-shadow: inset 0px 1px 0 rgba(0, 0, 0, .075);
}

.ProjectListToolbar .left-border-none {
    border-left: none;
}

.ProjectListToolbar input, .ProjectListToolbar select, .ProjectListToolbar .input-group-addon {
    border-radius: 0;
    height: 32px;
    font-size: 18px;
}

.ProjectListToolbar input::-webkit-input-placeholder {
    color: #d4d4d4;
    font-weight: 300;
    -webkit-text-decoration: none solid rgb(212, 212, 212);
            text-decoration: none solid rgb(212, 212, 212);
    letter-spacing: 1px;
}

.ProjectListToolbar input::-moz-placeholder {
    color: #d4d4d4;
    font-weight: 300;
    text-decoration: none solid rgb(212, 212, 212);
    letter-spacing: 1px;
}

.ProjectListToolbar input::-ms-input-placeholder {
    color: #d4d4d4;
    font-weight: 300;
    text-decoration: none solid rgb(212, 212, 212);
    letter-spacing: 1px;
}

.ProjectListToolbar input::placeholder {
    color: #d4d4d4;
    font-weight: 300;
    -webkit-text-decoration: none solid rgb(212, 212, 212);
            text-decoration: none solid rgb(212, 212, 212);
    letter-spacing: 1px;
}

.ProjectListToolbar select {
    color: #595959;
    font-weight: 300;
    -webkit-text-decoration: none solid rgb(89, 89, 89);
            text-decoration: none solid rgb(89, 89, 89);
    letter-spacing: 1px;
    padding: 0;
}

.ProjectListToolbar .input-group-addon {
    color: #9c9c9c;
}

.ProjectListToolbar .form-control-wrapper {
    position: relative;
}

.ProjectListToolbar .form-control-wrapper input {
    padding-left: 43px;
}

.ProjectListToolbar .form-control-wrapper:before {
    content: "A";
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 16px;
    padding-right: 0.5em;
    position: absolute;
    left: 16px;
    top: 5px;
    color: #d4d4d4;
    font-weight: 300;
    -webkit-text-decoration: none solid rgb(212, 212, 212);
            text-decoration: none solid rgb(212, 212, 212);
    letter-spacing: 1px;
}

.ProjectListToolbar .search:before {
    content: "\F002";
}

.project-list-wrapper>.container {
    padding-top: 50px;
    width: auto;
    background-color: white;
    margin-bottom: 50px;
    padding-bottom: 50px;
    margin-right: 5%;
    margin-left: 5%;
}

.project-list-wrapper .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
}

.project-list-wrapper .infinite-scroll-component>.project-list-item-container {
    background-color: white;
    width: 430px;
    padding: 0 20px;
    margin: 20px 0;
}

.project-list-wrapper .empty-projects {
    font-size: 24px;
    color: #595959;
    -webkit-text-decoration: none solid rgb(89, 89, 89);
            text-decoration: none solid rgb(89, 89, 89);
    margin-top: 124px;
}

.project-list-wrapper .infinite-scroll-component>.project-list-item-container .project-list-item-card {
    border: 1px solid #e3e3e3;
}

@media all and (max-width: 2200px) and (min-width: 2000px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 400px;
    }
}

@media all and (max-width: 2000px) and (min-width: 1700px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 350px;
    }
}

@media all and (max-width: 1700px) and (min-width: 1500px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 400px;
    }
}

@media all and (max-width: 1500px) and (min-width: 1400px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 350px;
    }
}

@media all and (max-width: 1400px) and (min-width: 1200px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 450px;
    }
}

@media all and (max-width: 1200px) and (min-width: 1000px){
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 350px;
    }
}

@media all and (max-width: 1000px) {
    .project-list-wrapper .infinite-scroll-component>.project-list-item-container {
        width: 450px;
    }
}

html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Montserrat, sans-serif;
}

body {
    background-color: #f7f7f7;
}

button:focus {
    outline: 0 !important;
}

button {
    outline: 0 !important;
}

button:active {
    outline: 0 !important;
}

.form-control:focus {
    border-color: #ccc !important;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #ccc !important;
}

