.ProjectListToolbar .transparent {
    background-color: #fff;
    box-shadow: inset 0px 1px 0 rgba(0, 0, 0, .075);
}

.ProjectListToolbar .left-border-none {
    border-left: none;
}

.ProjectListToolbar input, .ProjectListToolbar select, .ProjectListToolbar .input-group-addon {
    border-radius: 0;
    height: 32px;
    font-size: 18px;
}

.ProjectListToolbar input::placeholder {
    color: #d4d4d4;
    font-weight: 300;
    text-decoration: none solid rgb(212, 212, 212);
    letter-spacing: 1px;
}

.ProjectListToolbar select {
    color: #595959;
    font-weight: 300;
    text-decoration: none solid rgb(89, 89, 89);
    letter-spacing: 1px;
    padding: 0;
}

.ProjectListToolbar .input-group-addon {
    color: #9c9c9c;
}

.ProjectListToolbar .form-control-wrapper {
    position: relative;
}

.ProjectListToolbar .form-control-wrapper input {
    padding-left: 43px;
}

.ProjectListToolbar .form-control-wrapper:before {
    content: "A";
    font-family: 'Font Awesome\ 5 Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 16px;
    padding-right: 0.5em;
    position: absolute;
    left: 16px;
    top: 5px;
    color: #d4d4d4;
    font-weight: 300;
    text-decoration: none solid rgb(212, 212, 212);
    letter-spacing: 1px;
}

.ProjectListToolbar .search:before {
    content: "\f002";
}
