.footer-wrapper {
    width: 100%;
    display: inline-block;
    background-color: white;
    border: 1px solid #e0e0e0;
}

.footer-wrapper .logo-wrapper {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
}

.footer-wrapper .circle {
    border-radius: 85px;
    background: #1C66D1;
    color: white;
}

.footer-wrapper img {
    height: 70px;
}

.footer-wrapper .logo-text {
    text-align: left;
    padding-left: 15px;
    flex: 1 auto;
}

.footer-wrapper .footer-text {
    font-size: 16px;
    color: #9c9c9c;
    font-weight: 300;
    letter-spacing: 1px;
}

.footer-wrapper .terms-text {
    text-align: right;
    padding-left: 15px;
}

.footer-wrapper .navbar-brand {
    padding: 0;
}

@media all and (max-width: 1500px) {
    .footer-wrapper .container {
        width: 100%;
    }
}
